<template>
    <div class="alert-links">
        <p
            v-for="(action, i) in item.actions"
            :key="i">
            <remote-content-link
                :href="action.url"
                :dealer="dealer"
                :ticket-data="{
                    type: 'Other',
                    subject: item.alert_type_name
                }">
                {{ action.title }}
            </remote-content-link>
        </p>
    </div>
</template>

<script>
import RemoteContentLink from '@/components/globals/RemoteContentLink';

export default {
    components: {
        RemoteContentLink
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        dealer() {
            return {
                id: this.item.dealer_id,
                name: this.item.dealer_name,
                adAccountId: this.item.dealer_facebook_ad_account_id
            };
        }
    }
};
</script>
